<template>
  <div>
    <CRow>
      <CCol lg="7">
        <!-- <div class="row">
    <div class="col-sm-9"> -->
        <CNavbar light color="light" class="px-0">
          <h4>Academic-Year List</h4>
          <CForm inline>
            <b-input-group size="sm">
                <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
                ></b-form-input>
              </b-input-group>
            <CButton color="dark" size="sm" class="border-0">
              <CIcon name="cil-search" size="sm" />
            </CButton>
          </CForm>
          <CForm inline>
            <!-- <CButton
              color="outline-dark "
              class="my-2 my-sm-0 btn-out-dashed"
              type="submit"
              size="sm"
              @click="next_page"
              >+ Add New</CButton
            > -->
          </CForm>
        </CNavbar>
        <!-- </div>
        <div class="col-sm-3">
          
        </div>
    </div> -->

        <div class="table-wrapper py-3">
          <CRow>
            <CCol col="12" xl="12">
              <b-table
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              stacked="xs"
              show-empty
              small
              @filtered="onFiltered"
              >
              </b-table>
            </CCol>
          </CRow>
          <b-row>
          <b-col sm="2">
              <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
              ></b-pagination>
          </b-col>
          
          <b-col  offset-sm="7"  sm="3">
              <b-form-group horizontal label="Per page" label-size="sm" :label-cols="6" >
              <b-form-select :options="pageOptions" v-model="perPage" size="sm"/>
              </b-form-group>
          </b-col>
      </b-row>
        </div>
      </CCol>
      <CCol lg="5">
        <AddYear />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import AddYear from "./AddYear";
import axios from "axios";
import apis from "@/apis";
const base_url = process.env.VUE_APP_BASE_URL;

export default {
  name: "YearList",
  data() {
    return {
      items: [],
      filter:"",
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      fields: [
        // {
        //   key: "ac_year_id",
        //   label: "Year Id",
        //   _classes: "font-weight-bold",
        // },
        { key: "ac_year_description", label: "Year" },
        { key: "ac_year_start_date", label: "Start Date",formatter:'D_M_Y_formatDate' },
        { key: "ac_year_end_date", label: "End Date",formatter:'D_M_Y_formatDate' },
      ],
      activePage: 1,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  components: {
    AddYear,
  },
  methods: {
    D_M_Y_formatDate(date_parm)
    {
        let date = new Date(date_parm);
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        let new_date = dd + "-" + "0"+mm + "-" +yyyy ;
        this.receipt_new_date = new_date;
        return new_date;
    },
    next_page() {
      this.$router.push("/dashboard/AddYear");
     // this.$router.push({ name: "AddYear" });
    },
    get_data() {
      var promise = apis
        .list_AcademicYears()
        .then((response) => {
          this.items = response.data;
          this.totalRows = this.items.length;
        })
        .catch((errror) => {});
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
  },
  created() {
    this.get_data();
  },
};
</script>

<style></style>
